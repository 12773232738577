import { inject } from "vue";

import { isHaveSchoolTypeKey } from '/codebuild/output/src1316258542/src/cocoo_gov/src/utils/injectionKey.js';
export default {
  __name: 'SchoolTypeSwitch',
  props: {
    activeGrade: {
      type: String,
      required: true
    }
  },
  emits: ["setActiveGrade"],
  setup(__props, {
    emit
  }) {
    // @ts-check

    const isHaveSchoolType = inject(isHaveSchoolTypeKey);
    const setActiveGrade = grade => {
      emit("setActiveGrade", grade);
    };
    return {
      __sfc: true,
      emit,
      isHaveSchoolType,
      setActiveGrade
    };
  }
};