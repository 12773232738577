import { inject } from "vue";

import { isHaveSchoolTypeKey } from '/codebuild/output/src1316258542/src/cocoo_gov/src/utils/injectionKey.js';
export default {
  __name: 'SchoolTypeSwitch',
  props: {
    activeGrade: {
      type: String,
      required: true
    }
  },
  emits: ["setActiveGrade"],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    // @ts-check

    var isHaveSchoolType = inject(isHaveSchoolTypeKey);
    var setActiveGrade = function setActiveGrade(grade) {
      emit("setActiveGrade", grade);
    };
    return {
      __sfc: true,
      emit: emit,
      isHaveSchoolType: isHaveSchoolType,
      setActiveGrade: setActiveGrade
    };
  }
};