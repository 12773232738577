import _slicedToArray from "/codebuild/output/src1316258542/src/cocoo_gov/node_modules/@babel/runtime/helpers/esm/slicedToArray.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.entries.js";
import VueApexCharts from "vue-apexcharts";
export default {
  components: {
    apexchart: VueApexCharts
  },
  props: {
    schoolBarStackedData: {
      type: Object,
      default: function _default() {}
    }
  },
  data: function data() {
    return {
      series: [],
      chartOptions: {}
    };
  },
  watch: {
    schoolBarStackedData: function schoolBarStackedData() {
      if (this.schoolBarStackedData.class) {
        var colorList = {
          風邪: "#008FFB",
          発熱: "#00E396",
          頭痛: "#FEB019",
          腹痛: "#FF4560",
          嘔吐下痢: "#775DD0",
          "咳・息苦しさ": "#3F51B5",
          "受診・通院": "#03A9F4",
          発疹: "#4CAF50",
          ケガ: "#F9CE1D",
          その他病気: "#FF9800",
          体調不良: "#33B2DF",
          コロナ: "#546E7A",
          感染症: "#D4526E",
          "介護・手伝い": "#13D8AA",
          忌引: "#A5978B",
          特支: "#4ECDC4",
          家の都合: "#C7F464",
          その他: "#81D4FA"
        };
        var colors = Object.keys(this.schoolBarStackedData.value).map(function (v) {
          return colorList[v];
        });
        this.chartOptions = {
          chart: {
            type: "bar",
            stacked: true
          },
          plotOptions: {
            bar: {
              horizontal: true
            }
          },
          stroke: {
            width: 1,
            colors: ["#fff"]
          },
          title: {
            text: "学年クラス別 欠席理由別 欠席者数表示"
          },
          xaxis: {
            categories: this.schoolBarStackedData.class
          },
          yaxis: {
            title: {
              text: undefined
            }
          },
          tooltip: {
            y: {
              formatter: function formatter(val) {
                return val + "人";
              }
            }
          },
          fill: {
            opacity: 1
          },
          legend: {
            position: "top",
            horizontalAlign: "left",
            offsetX: 40
          },
          colors: colors
        };
        this.series = Object.entries(this.schoolBarStackedData.value).map(function (_ref) {
          var _ref2 = _slicedToArray(_ref, 2),
            key = _ref2[0],
            value = _ref2[1];
          return {
            name: key,
            data: value
          };
        });
      }
    }
  }
};