import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";

import { inject, computed } from 'vue';
import { allSchoolListKey, isHaveSchoolTypeKey } from '/codebuild/output/src1316258542/src/cocoo_gov/src/utils/injectionKey.js';
export default {
  __name: 'ForumSchoolCount',
  props: {
    selectSchoolIdList: {
      /** @type {PropType<number[]>} */
      type: Array,
      required: true
    }
  },
  setup: function setup(__props) {
    var props = __props;

    // @ts-check

    var allSchoolList = inject(allSchoolListKey);
    var isHaveSchoolType = inject(isHaveSchoolTypeKey);
    var schoolSelectTypeCount = computed(function () {
      var countTemp = {
        el: 0,
        jh: 0,
        hs: 0,
        ss: 0
      };
      allSchoolList.value.forEach(function (v) {
        if (v.type === "el") {
          if (props.selectSchoolIdList.includes(v.id)) {
            countTemp.el++;
          }
        } else if (v.type === "jh") {
          if (props.selectSchoolIdList.includes(v.id)) {
            countTemp.jh++;
          }
        } else if (v.type === "hs") {
          if (props.selectSchoolIdList.includes(v.id)) {
            countTemp.hs++;
          }
        } else if (v.type === "ss") {
          if (props.selectSchoolIdList.includes(v.id)) {
            countTemp.ss++;
          }
        }
      });
      return countTemp;
    });
    return {
      __sfc: true,
      props: props,
      allSchoolList: allSchoolList,
      isHaveSchoolType: isHaveSchoolType,
      schoolSelectTypeCount: schoolSelectTypeCount
    };
  }
};